<template>
    <layout>
        <div>
            <div class="d-flex justify-content-between">
                <h5>Manage Employees</h5>
                <div class="mt-15 d-flex justify-content-between">
                    <input v-model="search" @keyup="getList()" class="form-control form-control-sm mr-10" placeholder="Search">
                    <a data-toggle="modal" data-target="add-new-employee-modal" role="button" class="btn btn-primary btn-sm">
                        <i class="fa fa-plus"></i>&nbsp; Add New
                    </a>
                </div>
            </div>
            <div>
                <table class="table table-hover table-outer-bordered font-size-12">
                    <thead>
                        <tr>
                            <th width="30%" style="text-align:center;">🔨 NAME</th>
                            <th width="15%" style="text-align:center;">📜 INITIAL</th>
                            <th width="15%" style="text-align:center;">🏷️ POSITION</th>
                            <th width="20%" style="text-align:center;">📋 CATEGORY</th>
                            <th width="10%" style="text-align:center;">📜 STATUS</th>
                            <th width="20%" style="text-align:center;"></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="employee in data.data" :key="employee.id">
                            <td>{{ employee.lastname }}{{ (employee.lastname)?",":"" }} {{ employee.firstname }} {{ employee.middlename }}</td>
                            <td style="text-align:center;">{{ employee.initial }}</td>
                            <td style="text-align:center;">{{ employee.position }}</td>
                            <th style="text-align:center;">{{ employee.category }}</th>
                            <td style="text-align:center;"><div style="width: 100px; height:20px;" :style="{background: employee.color}" >&nbsp;</div></td>
                            <td class="text-right font-size-12">
                                <edit-button class="mr-5" :id="employee.id" v-on:editable-data="updateEditableEmployee"></edit-button>
                                <delete-button :id="employee.id" v-on:deleted="getList"></delete-button>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <div class="d-flex justify-content-end">
                    <pagination
                        v-if="data.links && data.links.length > 3"
                        :links="data.links"
                        :first_page_url="data.first_page_url"
                        :last_page_url="data.last_page_url"
                        :prev_page_url="data.prev_page_url"
                        :next_page_url="data.next_page_url"
                        v-on:click="getList"
                    ></pagination>
                </div>
            </div>
            <add-modal id="add-new-employee-modal" title="Add New Employee" v-on:added="getList"></add-modal>
            <edit-modal id="edit-employee-modal" title="Edit Employee" :data="editData" v-on:updated="getList"></edit-modal>
        </div>
    </layout>
</template>

<script>
import Pagination from '../../components/Pagination';
import AddModal from "./AddModal";
import EditModal from "./EditModal";
import DeleteButton from "./DeleteButton";
import EditButton from "./EditButton";

export default {
    components: {
        Pagination,
        AddModal,
        EditModal,
        DeleteButton,
        EditButton
    },
    data() {
        return {
            data: [],
            search: '',
            editData: {},
        }
    },
    created() {
        this.getList();
    },
    methods: {
        getList(link = null) {
            let newLink = link || `api/employees?page=1`;
            this.$http.get(`${newLink}&search=${this.search}`).then((response) => {
                this.data = response.data.list;
            });
        },
        updateEditableEmployee(data) {
            this.editData = data;
        }
    }
}
</script>

<template>
    <modal>
        <form @submit.prevent="checkForm" method="post" class="w-400 mw-full">
            <div class="form-group">
                <label for="firstname" class="required">First Name</label>
                <div class="invalid-feedback" v-if="errors.firstname">
                    <ul>
                        <li>This field is required.</li>
                    </ul>
                </div>
                <input v-model="form.firstname" type="text" class="form-control" id="edit_firstname" placeholder="First Name">
            </div>
            <div class="form-group">
                <label for="middlename">Middle Name</label>
                <input v-model="form.middlename" type="text" class="form-control" id="edit_middlename" placeholder="Middle Name">
            </div>
            <div class="form-group">
                <label for="lastname">Last Name</label>
                <input v-model="form.lastname" type="text" class="form-control" id="edit_lastname" placeholder="Last Name">
            </div>
            <div class="form-group">
                <label for="category">Category</label>
                <input v-model="form.category" type="text" class="form-control" id="edit_category" placeholder="Ex. Office">
            </div>
            <div class="form-group">
                <label for="initial" class="required">Initial</label>
                <div class="invalid-feedback" v-if="errors.initial">
                    <ul>
                        <li>This field is required.</li>
                    </ul>
                </div>
                <input v-model="form.initial" type="text" class="form-control" id="edit_initial" placeholder="Initial">
            </div>
            <div class="form-group">
                <label for="position" class="required">Position</label>
                <div class="invalid-feedback" v-if="errors.position">
                    <ul>
                        <li>This field is required.</li>
                    </ul>
                </div>
                <input v-model="form.position" type="text" class="form-control" id="edit_position" placeholder="Position">
            </div>
            <div class="form-group">
                <label for="status">Status</label>
                <select v-model="form.status" id="edit_status" class="form-control">
                    <option disabled>Select Status</option>
                    <option v-for="value in legends" :value="value.id" :key="value.id">{{ value.name }}</option>
                </select>
            </div>
            <div class="text-right mt-20">
                <a data-dismiss="modal" class="btn mr-5" role="button">Close</a>
                <button type="submit" class="btn btn-primary" role="button">Submit</button>
            </div>
        </form>
    </modal>
</template>

<script>
import Modal from "../../components/Modal";
import Toast from "../../mixins/Toast";
import * as halfmoon from 'halfmoon';

export default {
    name: 'edit-modal',
    components: { Modal },
    mixins: [ Toast ],
    props: [ 'data' ],
    emits: [ 'updated' ],
    data() {
        return {
            id: 0,
            errors: {
                firstname: false,
                initial: false,
                position: false,
                status: false,
            },
            form: {
                firstname: '',
                middlename: '',
                lastname: '',
                category: '',
                initial: '',
                position: '',
                status: ''
            },
            legends: this.getLegendStatus(),
        }
    },
    updated() {
            this.id = this.data.id;
            this.form.firstname = this.data.firstname;
            this.form.middlename = this.data.middlename;
            this.form.lastname = this.data.lastname;
            this.form.category = this.data.category;
            this.form.initial = this.data.initial;
            this.form.position = this.data.position;
            this.form.status = this.data.legend_status;
            halfmoon.toggleModal('edit-employee-modal');
    },
    methods: {
        getLegendStatus(){
            this.$http.get(`api/legend/getstatus`).then((response) => {
                this.legends = response.data.list;
            });
        },

        submitForm() {
            this.$http.post(`api/employee/edit/${this.id}`, this.form).then((response) => {
                this.ToastSuccess(response.data);
                halfmoon.toggleModal('edit-employee-modal');
                this.resetForm();
                this.$emit('updated');
            });
        },
        checkForm: function () {
            if (this.form.firstname != '' && this.form.initial != '' && this.form.position != '') {
                this.submitForm();
                return;
            }

            this.resetErrors();

            if (!this.form.firstname) {
                this.errors.firstname = true;
            }
            if (!this.form.initial) {
                this.errors.initial = true;
            }
            if (!this.form.position) {
                this.errors.position = true;
            }
            if (!this.form.status) {
                this.errors.status = true;
            }
        },
        
        resetErrors() {
            this.errors.firstname = false;
            this.errors.initial = false;
            this.errors.position = false;
            this.errors.status = false;
        },
        
        resetForm() {
            this.form.firstname = '';
            this.form.middlename = '';
            this.form.lastname = '';
            this.form.category = '';
            this.form.initial = '';
            this.form.position = '';
            this.form.status = '';
        },
    }
}
</script>
<template>
    <div class="modal" tabindex="-1" role="dialog">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <h5 class="modal-title">{{ title }}</h5>
                <div>
                    <slot></slot>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'modal',
    props: [
        'title'
    ]
}
</script>

<style>
.modal {
    position: fixed;
}
</style>
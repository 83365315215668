<template>
    <a @click="deleteEmployee(id)" class="btn btn-sm btn-danger">
        <i class="fa fa-trash"></i>
    </a>
</template>

<script>
import Swal from "../../mixins/Swal.js";
import Toast from "../../mixins/Toast";

export default {
    name: 'delete-button',
    props: [ 'id' ],
    mixins: [Toast, Swal],
    emits: [ 'deleted' ],
    methods: {
        async deleteEmployee(id) {
            let isConfirmed = await this.SwalDelete();

            if (isConfirmed) {
                this.$http.delete(`api/employee/delete/${id}`).then((response) => {
                    this.ToastSuccess(response.data);
                    this.$emit('deleted');
                });
            }
        }
    }
}
</script>
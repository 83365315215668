<template>
    <nav class="mt-20">
        <ul class="pagination">
            <li class="page-item mr-20" :class="{ disabled : prev_page_url == null}">
                <a :href="first_page_url" class="page-link" @click.prevent="callOnClick($event)">
                    First
                </a>
            </li>
            <li v-for="(page, index) in links" :key="index" class="page-item" :class="{ active : page.active, disabled : page.url == null}">
                <a v-if="page.label == 'Previous'" :href="page.url" class="page-link" @click.prevent="callOnClick($event)">
                    Prev
                </a>
                <a v-else-if="page.label == 'Next'" :href="page.url" class="page-link" @click.prevent="callOnClick($event)">
                    Next
                </a>
                <a v-else :href="page.url" class="page-link" @click.prevent="callOnClick($event)">
                    {{ page.label }}
                </a>
            </li>
            <li class="page-item ml-20" :class="{ disabled : next_page_url == null}">
                <a :href="last_page_url" class="page-link" @click.prevent="callOnClick($event)">
                    Last
                </a>
            </li>
        </ul>
    </nav>
</template>

<script>
export default {
    name: 'pagination',
    props: [
        'links',
        'first_page_url',
        'last_page_url',
        'prev_page_url',
        'next_page_url'
    ],
    emits: [ 'click' ],
    methods: {
        callOnClick(e) {
            let link = e.srcElement.href;
            this.$emit('click', link);
        }
    }
}
</script>